<template>
  <layout-vertical>

    <router-view />

    <div
      slot="vertical-menu-header"
      class="d-flex flex-column align-items-center h-100"
    >
      <div class="logo__container">
        <h1 class="h5 font-medium-5 text-dark my-1">
          Toqoo <span class="text-primary">Material</span>
        </h1>
      </div>
      <b-row class="w-100 p-2" v-if="userData">
        <b-col
          cols="3"
          class="px-0 text-right"
        >
          <b-avatar
            variant="info"
            :src="userData.photo_url"
          />
        </b-col>
        <b-col
          cols="8"
          class="pr-0"
        >
          <div class="user__info">
            <h1 class="mb-0 size14 text-dark text-truncate">
              {{ userData.name }}
            </h1>
          </div>
        </b-col>
        <b-col
          cols="1"
          class="px-0 text-right d-flex justify-content-end align-items-center"
        >
          <b-navbar-nav>
            <b-nav-item-dropdown
              right
              toggle-class="d-flex align-items-center"
              class="custom__dropdown"
            >
              <template
                #button-content
              >
                <div class="">
                  <b-img
                    :src="imgDot"
                    width="10"
                  />
                </div>
              </template>
              <b-dropdown-item
                v-if="checkPermission('kasir')"
                link-class="d-flex align-items-center"
                @click="startTutorial"
              >
                <feather-icon
                  v-if="!isLoading"
                  size="16"
                  icon="PlayIcon"
                  class="mr-50"
                />
                <b-spinner
                  v-else
                  small
                />
                <span>Mulai Tutorial</span>
              </b-dropdown-item>
              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="logout"
              >
                <feather-icon
                  size="16"
                  icon="LogOutIcon"
                  class="mr-50"
                />
                <span>Logout</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-col>
      </b-row>
    </div>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import {
  BAvatar, BImg, BRow, BCol, BNavbarNav, BNavItemDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { checkPermission } from '@/auth/utils'
// import { $themeConfig } from '@themeConfig'
// import Navbar from '../components/Navbar.vue'

import imgDot from '@/assets/images/icons/dot-4.png'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    BAvatar,
    BImg,
    BRow,
    BCol,
    BNavItemDropdown,
    BDropdownItem,
    BNavbarNav,
    BSpinner,
    // Navbar,
  },
  setup() {
    return {
      checkPermission,
    }
  },
  data() {
    return {
      imgDot,
      userData: JSON.parse(localStorage.getItem('userData')),
      jabatan: '',
      isLoading: false,
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  mounted() {
    // const that = this
    // this.$echo.channel(`NewLoginDetected.${this.userData.id}`)
    //   .listen('.NewLoginDetected', () => {
    //     this.$store.dispatch('profile/getProfile')
    //       .then(() => {
    //         // Do nothing
    //       })
    //       .catch(error => {
    //         // If token is expire (401), refresh page
    //         if (error.response) {
    //           if (error.response.status) {
    //             alert('You have logged in on another device!')
    //             // window.location.reload()
    //           }
    //         }
    //       })
    //   })
  },
  methods: {
    startTutorial() {
      this.isLoading = true
      this.$store.dispatch('tutorial/postData')
        .then(result => {
          const userData = JSON.stringify(result.data.data)
          localStorage.setItem('userData', userData)
          // this.$bvModal.show('modal-tutorial')
          this.isLoading = false
          window.location.reload()
          // this.$router.push({ name: 'cashier.index', query: { _r: Date.now() } })
        }).catch(err => {
          this.isLoading = false
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style lang="scss">
.b-nav-dropdown.custom__dropdown .dropdown-toggle::after {
  background-image: none !important;
  width: unset;
  height: unset;
}
.logo__container {
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid #f6f7fa;
  background: #f6f7fa;

  h1 {
    text-align: center;
  }
}
</style>
